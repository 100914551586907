import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';
import {
  Container,
  Card,
  Avatar,
  Typography,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Drawer
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Importing the menu icon

const UserProfileScreen = () => {
  const [userData, setUserData] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();
  const auth = getAuth();
  const database = getDatabase();

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = ref(database, 'users/' + user.uid);
        onValue(userRef, (snapshot) => {
          setUserData(snapshot.val());
        });
      }
    };

    // Check if the user is authenticated
    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchData();
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });
  }, [auth, database, navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login after logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  if (!userData) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      {/* Add embedded CSS styles */}
      <style>
        {`
          .header {
            position: relative;
            width: 100%;
            padding-top: 16px;
            margin-bottom: 16px;
          }
          .logoutButton {
            position: absolute;
            right: 0;
          }
          // .menu-button {
          //   position: fixed;
          //   top: 15px;
          //   left: 15px;
          //   font-size: 24px;
          //   background: none;
          //   border: none;
          //   cursor: pointer;
          //   z-index: 1000;
          // }
          .drawer-container {
            width: 250px;
            padding: 20px;
            background-color: #f0f0f0; /* Softer light background color */
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .drawer-container ul {
            list-style-type: none; /* Remove bullet points */
            padding: 0; /* Remove default padding */
            margin: 0; /* Remove default margin */
          }
          .drawer-container ul li {
            margin-bottom: 16px; /* More space between list items */
          }
          .drawer-container ul li a,
          .drawer-container ul li button {
            text-decoration: none;
            color: #333; /* Dark color for text */
            font-weight: 500; /* Medium weight for text */
            display: block;
            padding: 10px;
            border-radius: 4px;
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;
          }
          .drawer-container ul li a:hover,
          .drawer-container ul li button:hover {
            background-color: #007bff; /* Blue background on hover */
            color: #fff; /* White text on hover */
          }
          .drawer-container ul li button {
            border: none;
            background: none;
            width: 100%;
            text-align: left;
          }
        `}
      </style>

      <Container
        sx={{
          padding: '16px',
          maxWidth: { xs: '360px', md: '400px', lg: '700px' },
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        {/* Header Section with Toggle */}
        <Box className="header">
          <IconButton
            onClick={toggleDrawer(true)}
            className="menu-button"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#0A3161', textAlign: 'center' }}>
            Profile
          </Typography>
          {/* <Button
            variant="contained"
            color="error"
            onClick={handleLogout}
            className="logoutButton"
          >
            Log Out
          </Button> */}
        </Box>

        {/* Drawer (Sidebar Menu) */}
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <Box
            className="drawer-container"
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ul>
              <li><Link to="/HomeScreen">Home</Link></li>
              <li><Link to="/UserProfileScreen">User Profile</Link></li>
              <li><Link to="/obl-team">OBL Team</Link></li>
              <li><Link to="/friends-search">Friends Search</Link></li>
              <li><Link to="/notifications">Notifications</Link></li>
              <li><Link to="/topic-search">Topic Search</Link></li>
              <li><Link to="/submit">Submit</Link></li>
              <li><button onClick={handleLogout}>Log Out</button></li>
            </ul>
          </Box>
        </Drawer>

        {/* Profile and Information Section */}
        <Card sx={{ padding: '20px', textAlign: 'center', boxShadow: 'none', width: '100%' }}>
          <Avatar
            src="/logo.webp"
            alt="Logo"
            sx={{
              width: 100,
              height: 100,
              margin: 'auto',
              border: '2px solid #007BFF',
              marginBottom: '16px',
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
            {userData.Username}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '4px' }}>
            Email: {userData.email}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '4px' }}>
            First Name: {userData.firstName}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '4px' }}>
            Last Name: {userData.lastName}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '4px' }}>
            Phone: {userData.phone}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '4px' }}>
            Address: {userData.address}
          </Typography>
          <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '4px' }}>
            Journalist: {userData.isJournalist ? 'Yes' : 'No'}
          </Typography>
          <Button
            variant="contained"
            color="error"
            sx={{ marginTop: '16px', borderRadius: '8px' }}
            onClick={handleLogout}
          >
            Log Out
          </Button>
        </Card>
      </Container>
    </>
  );
};

export default UserProfileScreen;

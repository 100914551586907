import React, { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import {
  Container,
  Card,
  Avatar,
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Drawer
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Importing the menu icon

const OBLTeamScreen = () => {
  const { user } = useAuth();
  const [journalists, setJournalists] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJournalists = async () => {
      try {
        const db = getDatabase();
        const snapshot = await get(ref(db, 'users'));
        const data = snapshot.val();

        if (data) {
          const journalistList = Object.values(data)
            .filter(user => user.isJournalist)
            .map(user => ({
              username: user.username,
              profileImage: user.profileImage || 'default-profile.png',
              isJournalist: user.isJournalist,
            }));
          setJournalists(journalistList);
        } else {
          console.log('No journalists found');
        }
      } catch (error) {
        console.error('Error fetching journalists:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJournalists();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const renderJournalist = (journalist) => (
    <Card
      key={journalist.username}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        margin: '8px 0',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        textAlign: 'center'
      }}
    >
      <Avatar
        src={journalist.profileImage}
        alt="Profile"
        sx={{ width: 80, height: 80, marginBottom: '8px' }}
      />
      <Typography variant="body1" sx={{ color: '#0A3161', marginBottom: '8px' }}>
        {journalist.username}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/JournalistProfileScreen', { state: { journalist } })}
        sx={{ borderRadius: '8px' }}
      >
        Visit Profile
      </Button>
    </Card>
  );

  const filteredJournalists = journalists.filter(journalist =>
    journalist.username.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      {/* Add embedded CSS styles */}
      <style>
        {`
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 24px;
            padding-top: 16px;
          }
          .menu-button {
            position: relative;
            left: 0;
          }
          .drawer-container {
            width: 250px;
            padding: 20px;
            background-color: #f0f0f0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .drawer-container ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          .drawer-container ul li {
            margin-bottom: 16px;
          }
          .drawer-container ul li a,
          .drawer-container ul li button {
            text-decoration: none;
            color: #333;
            font-weight: 500;
            display: block;
            padding: 10px;
            border-radius: 4px;
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;
          }
          .drawer-container ul li a:hover,
          .drawer-container ul li button:hover {
            background-color: #007bff;
            color: #fff;
          }
          .drawer-container ul li button {
            border: none;
            background: none;
            width: 100%;
            text-align: left;
          }
        `}
      </style>

      <Container
        sx={{
          padding: '16px',
          maxWidth: { xs: '360px', md: '400px', lg: '700px' },
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        {/* Header Section with Toggle */}
        <Box className="header">
          <IconButton onClick={toggleDrawer(true)} className="menu-button">
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              color: '#B22234',
              flexGrow: 1,
              textAlign: 'center'
            }}
          >
            OBL Team
          </Typography>
        </Box>

        {/* Drawer (Sidebar Menu) */}
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <Box
            className="drawer-container"
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ul>
              <li><Link to="/HomeScreen">Home</Link></li>
              <li><Link to="/UserProfileScreen">User Profile</Link></li>
              <li><Link to="/obl-team">OBL Team</Link></li>
              <li><Link to="/friends-search">Friends Search</Link></li>
              <li><Link to="/notifications">Notifications</Link></li>
              <li><Link to="/topic-search">Topic Search</Link></li>
              <li><Link to="/submit">Submit</Link></li>
              <li><button onClick={() => navigate('/login')}>Log Out</button></li>
            </ul>
          </Box>
        </Drawer>

        <TextField
          label="Search journalists..."
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />

        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{ width: '100%' }}>
            {filteredJournalists.map((journalist) => renderJournalist(journalist))}
          </Box>
        )}
      </Container>
    </>
  );
};

export default OBLTeamScreen;

import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, push, set } from 'firebase/database';
import { useAuth } from './useAuth';
import { useLocation } from 'react-router-dom';

const JournalistProfileScreen = () => {
  const { user } = useAuth();
  const location = useLocation();
  const { journalist } = location.state || {}; // Accessing the passed state
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const database = getDatabase();

  useEffect(() => {
    if (journalist) {
      const fetchComments = async () => {
        try {
          const commentsRef = ref(database, `comments/${journalist.username}`);
          onValue(commentsRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
              const commentList = Object.values(data);
              setComments(commentList);
            } else {
              setComments([]);
            }
          });
        } catch (error) {
          console.error('Error fetching comments:', error);
        }
      };

      fetchComments();
    }
  }, [journalist, database]);

  const handleAddComment = async () => {
    if (newComment.trim()) {
      const commentId = push(ref(database, 'comments')).key;
      const newCommentObj = {
        id: commentId,
        text: newComment,
        username: user?.displayName || 'Anonymous',
        date: new Date().toISOString(),
      };

      set(ref(database, `comments/${journalist.username}/${commentId}`), newCommentObj);
      setComments([...comments, newCommentObj]);
      setNewComment('');
    }
  };

  const handleAddToFavorites = async () => {
    if (user) {
      const userRef = ref(database, `users/${user.uid}/favorites`);
      onValue(userRef, (snapshot) => {
        const currentFavorites = snapshot.val() || [];

        if (!currentFavorites.includes(journalist.username)) {
          set(userRef, [...currentFavorites, journalist.username]);
        }
      });
    }
  };

  const renderComment = (comment) => (
    <div style={styles.commentContainer} key={comment.id}>
      <p style={styles.commentText}>
        {comment.username}: {comment.text}
      </p>
      <p style={styles.commentDate}>{comment.date}</p>
    </div>
  );

  return (
    <div style={styles.container}>
      {journalist ? (
        <>
          <p style={styles.username}>{journalist.username}</p>
          <button style={styles.button} onClick={handleAddToFavorites}>
            Add to Favorites
          </button>

          {comments.map(renderComment)}

          <input
            style={styles.input}
            placeholder="Write a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleAddComment()}
          />
          <button style={styles.button} onClick={handleAddComment}>
            Add Comment
          </button>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

const styles = {
  // ... your styles
  container: {
    padding: '16px',
    margin: 'auto',
    maxWidth: '600px',
  },
  button: {
    backgroundColor: '#0A3161',
    color: '#fff',
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    margin: '10px 0',
    cursor: 'pointer',
  },
  username: {
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '16px',
    color: '#B22234',
  },
  commentContainer: {
    padding: '8px',
    borderBottom: '1px solid #ccc',
  },
  commentText: {
    fontSize: '16px',
    color: '#B22234',
  },
  commentDate: {
    fontSize: '12px',
    color: '#0A3161',
  },
  input: {
    width: '100%',
    padding: '8px',
    marginTop: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },

};

export default JournalistProfileScreen;

// src/LoginScreen.js
import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithCredential, sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { useAuth } from './AuthContext'; // Import useAuth from context
const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();
  const { login } = useAuth(); // Get login function from context

  const handleEmailLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('Email login successful');
      navigate('/homescreen'); // Redirect to HomeScreen after successful login
    } catch (error) {
      console.error('Email login error:', error);
      alert('Invalid email or password. Please try again.');
    }
  };

  const handleGoogleLogin = async (response) => {
    const { tokenId } = response;
    const googleCredential = GoogleAuthProvider.credential(tokenId);
    try {
      await signInWithCredential(auth, googleCredential);
      console.log('Google login successful');
      navigate('/homescreen'); // Redirect to HomeScreen after successful login
    } catch (error) {
      console.error('Google login error:', error);
      alert('Google login failed. Please try again.');
    }
  };

  const handlePasswordReset = async () => {
    if (!email) {
      alert('Please enter your email to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Password reset error:', error);
      alert('Failed to send password reset email. Please try again.');
    }
  };

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userData = await getDoc(userRef);
        if (userData.exists() && userData.data().isJournalist) {
          navigate('/journalist');
        } else {
          navigate('/homescreen'); // Redirect to HomeScreen when user is logged in
        }
      } else {
        console.log('No user is signed in');
      }
    });

    return () => subscriber(); // unsubscribe on unmount
  }, [auth, navigate]);

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Log In</Typography>
      <TextField 
        label="Email" 
        variant="outlined" 
        fullWidth 
        margin="normal" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField 
        label="Password" 
        variant="outlined" 
        type="password" 
        fullWidth 
        margin="normal" 
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        fullWidth 
        onClick={handleEmailLogin}
      >
        Login
      </Button>
      <Typography 
        variant="body2" 
        align="right" 
        style={{ cursor: 'pointer', marginTop: '10px' }} 
        onClick={handlePasswordReset}
      >
        Forgot Password?
      </Typography>
      {/* <GoogleLogin 
        clientId="YOUR_GOOGLE_CLIENT_ID" 
        buttonText="Login with Google" 
        onSuccess={handleGoogleLogin} 
        onFailure={handleGoogleLogin}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '20px' }}
      /> */}
      <Typography 
        variant="body2" 
        align="center" 
        style={{ marginTop: '20px' }}
      >
        No Account? <span style={{ color: '#B31942', cursor: 'pointer' }} onClick={() => navigate('/register')}>Create Account</span>
      </Typography>
    </div>
  );
};

export default LoginScreen;

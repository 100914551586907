// src/HelloWorld.js
import React from 'react';

const HelloWorld = () => {
  return (
    <div>
      <h1>Hello World</h1>
    </div>
  );
};

export default HelloWorld;

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import HomePage from './HomePage';
import LoginScreen from './LoginScreen';
import RegisterScreen from './RegisterScreen';
import HomeScreen from './HomeScreen';
import HelloWorld from './HelloWorld';
import OBLTeamScreen from './OBLTeamScreen';
import FriendsSearchScreen from './FriendsSearchScreen';
import TopicSearchScreen from './TopicSearchScreen';
import UserProfileScreen from './UserProfileScreen';
import SubmitScreen from './SubmitScreen';
import JournalistProfileScreen from './JournalistProfileScreen';
import NavigationMenu from './NavigationMenu';

const AppContent = () => {
  const { isAuthenticated } = useAuth(); // Now this hook should work correctly

  return (
    <div>
      {isAuthenticated && <NavigationMenu />} {/* Show menu only when logged in */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/homescreen" element={<HomeScreen />} />
        <Route path="/hello" element={<HelloWorld />} />
        <Route path="/obl-team" element={<OBLTeamScreen />} />
        <Route path="/friends-search" element={<FriendsSearchScreen />} />
        <Route path="/topic-search" element={<TopicSearchScreen />} />
        <Route path="/UserProfileScreen" element={<UserProfileScreen />} />
        <Route path="/JournalistProfileScreen" element={<JournalistProfileScreen/>} />
        <Route path="/submit" element={<SubmitScreen />} />
      </Routes>
    </div>
  );
};

const App = () => (
  <AuthProvider>
    {/* <Router> */}
      <AppContent />
    {/* </Router> */}
  </AuthProvider>
);

export default App;

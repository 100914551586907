// src/HomePage.js
import React from 'react';
import { Button, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: 'center', marginTop: '150px' }}>
      {/* Updated logo to match design */}
      <img
        src="/logo.webp" // Ensure the image is correctly located in the public folder
        alt="Logo"
        style={{ width: '300px', marginBottom: '40px' }}
      />
      <div style={{ margin: '0 auto', maxWidth: '300px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/login')}
          style={{
            marginTop: '20px',
            width: '100%',
            height: '50px',
            backgroundColor: '#333', // Darker color to match the example
            fontSize: '18px',
            textTransform: 'none', // Keep text as is, without uppercase
            borderRadius: '30px', // Rounded corners
          }}
        >
          Log In
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate('/register')}
          style={{
            marginTop: '15px',
            width: '100%',
            height: '50px',
            fontSize: '18px',
            textTransform: 'none', // Keep text as is
            borderRadius: '30px', // Rounded corners
            borderColor: '#333', // Match the outlined button to the style
          }}
        >
          Create Account
        </Button>
        <Link
          href="#"
          variant="body2"
          style={{
            marginTop: '20px',
            display: 'block',
            color: '#666', // Grey color to match example
            textDecoration: 'underline',
            fontSize: '14px',
          }}
        >
          Problems logging in?
        </Link>
      </div>
    </div>
  );
};

export default HomePage;

import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
  Box,
  Card,
  Avatar,
  IconButton,
  Drawer
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import './style.css'; // Ensure your CSS file is imported

const HomeScreen = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('National');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is signed in');
      } else {
        console.log('User is signed out');
        navigate('/login');
      }
    });

    return () => subscriber(); // Unsubscribe on unmount
  }, [auth, navigate]);

  const handleSearch = () => {
    console.log('Search query:', searchQuery);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      console.log('User signed out!');
      navigate('/login');
    } catch (error) {
      console.error('Logout error', error);
      alert('An error occurred while logging out. Please try again.');
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <Container
      sx={{
        padding: '16px',
        maxWidth: { xs: '360px', md: '400px', lg: '700px' },
        margin: 'auto',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff',
      }}
    >
      {/* Header Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        {/* Toggle Button */}
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#0A3161' }}>
          Home
        </Typography>
        <Button variant="contained" color="error" onClick={handleLogout}>
          Log Out
        </Button>
      </Box>

      {/* Drawer (Sidebar Menu) */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          className="drawer-container" // Apply the CSS class
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <ul>
            <li><Link to="/HomeScreen">Home</Link></li>
            <li><Link to="/UserProfileScreen">User Profile</Link></li>
            <li><Link to="/obl-team">OBL Team</Link></li>
            <li><Link to="/friends-search">Friends Search</Link></li>
            <li><Link to="/notifications">Notifications</Link></li>
            <li><Link to="/topic-search">Topic Search</Link></li>
            <li><Link to="/submit">Submit</Link></li>
            <li><button onClick={handleLogout}>Log Out</button></li>
          </ul>
        </Box>
      </Drawer>

      {/* Profile and Logo Section */}
      <Card sx={{ marginY: 2, paddingY: 2, textAlign: 'center', boxShadow: 'none' }}>
        <Avatar
          src="/logo.webp"
          alt="Logo"
          sx={{ width: 80, height: 80, margin: 'auto', border: '2px solid #007BFF' }}
        />
        <Typography variant="h6" gutterBottom sx={{ marginTop: '8px', color: '#555' }}>
          User Registration Address Location
        </Typography>
      </Card>

      {/* Search Section */}
      <TextField
        label="Search..."
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        variant="outlined"
        sx={{ marginBottom: '16px', borderRadius: '8px' }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSearch}
        sx={{ marginBottom: '16px', borderRadius: '8px' }}
      >
        Search
      </Button>

      {/* Dropdown and other elements */}
      <FormControl fullWidth variant="outlined" sx={{ marginBottom: '16px' }}>
        <InputLabel>Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          label="Category"
          sx={{ borderRadius: '8px' }}
        >
          <MenuItem value="National">National</MenuItem>
          <MenuItem value="Border">Border</MenuItem>
          <MenuItem value="State">State</MenuItem>
          <MenuItem value="County">County</MenuItem>
          <MenuItem value="City">City</MenuItem>
        </Select>
      </FormControl>

      <Button variant="contained" color="error" fullWidth sx={{ borderRadius: '8px' }}>
        SUBMIT
      </Button>
    </Container>
  );
};

export default HomeScreen;

import React, { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useNavigate, Link } from 'react-router-dom';
import { getDatabase, ref, child, get, push, set } from 'firebase/database';
import {
  Container,
  Card,
  Avatar,
  Typography,
  Box,
  Button,
  TextField,
  IconButton,
  Drawer
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Importing the menu icon

const FriendsSearchScreen = () => {
  const { user, username } = useAuth();
  const navigate = useNavigate();
  const [friends, setFriends] = useState([]);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [search, setSearch] = useState('');
  const [alphabet, setAlphabet] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for sidebar toggle
  const db = getDatabase(); // Initialize the Firebase database

  useEffect(() => {
    const fetchFriends = async () => {
      if (!user || !username) return;

      try {
        const dbRef = ref(db);
        const snapshot = await get(child(dbRef, 'users'));
        const data = snapshot.val();

        if (data) {
          const friendList = Object.values(data)
            .filter((usr) => 
              !usr.isJournalist && 
              usr.uid !== user.uid &&
              usr.username.toLowerCase() !== username.toLowerCase()
            )
            .map((usr) => ({
              username: usr.username,
              uid: usr.uid,
              profileImage: usr.profileImage || 'default-profile.png',
            }));

          setFriends(friendList);
        } else {
          console.log('No friends found');
        }
      } catch (error) {
        console.error('Error fetching friends:', error);
      }
    };

    fetchFriends();
  }, [user, username]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleAddComment = async () => {
    if (selectedFriend && newComment.trim()) {
      const commentId = push(child(ref(db), 'comments')).key; // Generate a new unique key
      const newCommentObj = {
        id: commentId,
        text: newComment,
        username: user?.displayName || 'Anonymous',
        date: new Date().toISOString(),
      };

      await set(ref(db, `comments/${selectedFriend.username}/${commentId}`), newCommentObj);
      setComments([...comments, newCommentObj]);
      setNewComment('');
    }
  };

  const handleReply = async (commentId, replyText) => {
    if (selectedFriend && replyText.trim()) {
      const replyId = push(child(ref(db), 'comments')).key; // Generate a new unique key for the reply
      const replyObj = {
        id: replyId,
        text: replyText,
        username: user?.displayName || 'Anonymous',
        date: new Date().toISOString(),
      };

      await set(ref(db, `comments/${selectedFriend.username}/${commentId}/replies/${replyId}`), replyObj);

      const updatedComments = comments.map((comment) => {
        if (comment.id === commentId) {
          comment.replies = comment.replies ? [...comment.replies, replyObj] : [replyObj];
        }
        return comment;
      });

      setComments(updatedComments);
    }
  };

  const renderComment = (comment) => (
    <Box key={comment.id} sx={{ borderBottom: '1px solid #ccc', padding: '8px' }}>
      <Typography sx={{ fontSize: '16px' }}>{comment.username}: {comment.text}</Typography>
      <Typography sx={{ fontSize: '12px', color: '#888' }}>{comment.date}</Typography>
      {comment.replies && comment.replies.map((reply) => (
        <Box key={reply.id} sx={{ marginLeft: '16px', marginTop: '4px' }}>
          <Typography sx={{ fontSize: '14px' }}>{reply.username}: {reply.text}</Typography>
          <Typography sx={{ fontSize: '12px', color: '#888' }}>{reply.date}</Typography>
        </Box>
      ))}
      <TextField
        placeholder="Write a reply..."
        onKeyPress={(event) => event.key === 'Enter' && handleReply(comment.id, event.target.value)}
        sx={{ width: '100%', marginTop: '8px' }}
      />
    </Box>
  );

  const renderFriend = (friend) => (
    <Card key={friend.username} onClick={() => setSelectedFriend(friend)} sx={{ cursor: 'pointer', padding: '8px', marginBottom: '8px', textAlign: 'center' }}>
      <Typography sx={{ fontSize: '16px', fontWeight: selectedFriend?.username === friend.username ? 'bold' : 'normal' }}>{friend.username}</Typography>
    </Card>
  );

  const filteredFriends = friends.filter(friend =>
    friend.username.toLowerCase().includes(search.toLowerCase()) && friend.username.toLowerCase().startsWith(alphabet.toLowerCase()));

  return (
    <Container
      sx={{
        padding: '16px',
        maxWidth: { xs: '360px', md: '400px', lg: '700px' },
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        backgroundColor: '#fff',
      }}
    >
      {/* Header Section with Toggle */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <IconButton onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#B22234' }}>
          Friends Search
        </Typography>
        <Button variant="contained" color="error" onClick={() => navigate('/login')}>
          Log Out
        </Button>
      </Box>

      {/* Drawer (Sidebar Menu) */}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <ul style={{ listStyleType: 'none', padding: '10px' }}>
            <li><Link to="/HomeScreen">Home</Link></li>
            <li><Link to="/UserProfileScreen">User Profile</Link></li>
            <li><Link to="/obl-team">OBL Team</Link></li>
            <li><Link to="/friends-search">Friends Search</Link></li>
            <li><Link to="/notifications">Notifications</Link></li>
            <li><Link to="/topic-search">Topic Search</Link></li>
            <li><Link to="/submit">Submit</Link></li>
            <li><button onClick={() => navigate('/login')}>Log Out</button></li>
          </ul>
        </Box>
      </Drawer>

      <TextField
        label="Search friends..."
        variant="outlined"
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ marginBottom: '16px' }}
      />

      <Box sx={{ width: '100%' }}>
        {filteredFriends.map(renderFriend)}
      </Box>

      {selectedFriend && (
        <Box sx={{ width: '100%', marginTop: '16px' }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
            Comments for {selectedFriend.username}
          </Typography>
          {comments.map(renderComment)}
          <TextField
            placeholder="Write a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleAddComment()}
            sx={{ width: '100%', marginTop: '8px' }}
          />
        </Box>
      )}
    </Container>
  );
};

export default FriendsSearchScreen;

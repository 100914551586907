// RegisterScreen.js
import React, { useState } from 'react';
import { Button, TextField, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithCredential } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { GoogleLogin } from 'react-google-login';

const RegisterScreen = () => {
  const [date, setDate] = useState(new Date());
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [subscribeNews, setSubscribeNews] = useState(false);
  const [isJournalist, setIsJournalist] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleEmailRegistration = async () => {
    if (password !== confirmPass) {
      alert('Passwords do not match.');
      return;
    }
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        username: name,
        email,
        dob: date.toDateString(),
        firstName,
        lastName,
        phone,
        address,
        subscribeNews,
        isJournalist,
      });
      navigate('/home');
    } catch (error) {
      console.error(error);
      alert('Registration failed. Please try again.');
    }
  };

  const handleGoogleRegistration = async (response) => {
    const { tokenId } = response;
    const googleCredential = GoogleAuthProvider.credential(tokenId);
    try {
      const userCredential = await signInWithCredential(auth, googleCredential);
      const user = userCredential.user;

      if (userCredential.additionalUserInfo?.isNewUser) {
        const userRef = doc(db, 'users', user.uid);
        await setDoc(userRef, {
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
          isJournalist,
        });
      }

      navigate('/home');
    } catch (error) {
      console.error(error);
      alert('Google registration failed. Please try again.');
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: '0 auto' }}>
      <Typography variant="h4" gutterBottom>Create Account</Typography>
      <TextField label="Full Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="normal" />
      <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" fullWidth margin="normal" />
      <TextField label="Password" value={password} onChange={(e) => setPassword(e.target.value)} type="password" fullWidth margin="normal" />
      <TextField label="Confirm Password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} type="password" fullWidth margin="normal" />
      <TextField label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} fullWidth margin="normal" />
      <TextField label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth margin="normal" />
      <TextField label="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} type="tel" fullWidth margin="normal" />
      {/* <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth margin="normal" /> */}
      <FormControlLabel
        control={<Checkbox checked={subscribeNews} onChange={(e) => setSubscribeNews(e.target.checked)} />}
        label="Subscribe to news"
      />
      <FormControlLabel
        control={<Checkbox checked={isJournalist} onChange={(e) => setIsJournalist(e.target.checked)} />}
        label="I am a journalist"
      />
      <Button onClick={handleEmailRegistration} variant="contained" color="primary" fullWidth>
        Register
      </Button>
      {/* <GoogleLogin
        clientId="YOUR_GOOGLE_CLIENT_ID"
        buttonText="Register with Google"
        onSuccess={handleGoogleRegistration}
        onFailure={console.error}
        cookiePolicy={'single_host_origin'}
      /> */}
    </div>
  );
};

export default RegisterScreen;

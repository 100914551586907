// NavigationMenu.js
import React, { useState } from 'react'; // Import useState
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import './NavigationMenu.css'; // Import the CSS 
const NavigationMenu = () => {
  const { logout } = useAuth(); // Use logout function from context
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Hamburger Icon */}
      <button className="menu-button" onClick={toggleMenu}>
        ☰
      </button>
      
      {/* Sidebar Menu */}
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/HomeScreen" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/UserProfileScreen" onClick={toggleMenu}>User Profile</Link></li>
          <li><Link to="/obl-team" onClick={toggleMenu}>OBL Team</Link></li>
          <li><Link to="/friends-search" onClick={toggleMenu}>Friends Search</Link></li>
          <li><Link to="/notifications" onClick={toggleMenu}>Notifications</Link></li>
          <li><Link to="/topic-search" onClick={toggleMenu}>Topic Search</Link></li>
          <li><Link to="/submit" onClick={toggleMenu}>Submit</Link></li>
          <li><button onClick={logout}>Log Out</button></li>
        </ul>
      </nav>
    </div>
  );
};

export default NavigationMenu;

// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC0mLLm4KQWGAlmxPfTrKH1RIPhjr4PHek",
  authDomain: "opreationborderlights.firebaseapp.com",
  databaseURL: "https://opreationborderlights-default-rtdb.firebaseio.com",
  projectId: "opreationborderlights",
  storageBucket: "opreationborderlights.appspot.com",
  messagingSenderId: "828764576930",
  appId: "1:828764576930:web:f707fd2182b4a3b4ad4bfb",
  measurementId: "G-JRLDEPG3FE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export the Firebase instances
export const auth = getAuth(app);
export const db = getFirestore(app);

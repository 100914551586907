import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Card,
  IconButton,
  Drawer,
  Button
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from 'react-router-dom';

const TopicSearchScreen = () => {
  const [search, setSearch] = useState('');
  const [topics] = useState([
    'Drugs', 'Human Trafficking', 'Immigration Issues', 'Weapons', 'Cartel Related', 'Report A Tip'
  ]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();

  const filteredTopics = topics.filter(topic => topic.toLowerCase().includes(search.toLowerCase()));

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <>
      {/* Add embedded CSS styles */}
      <style>
        {`
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16px;
            padding-bottom: 16px;
          }
          .drawer-container {
            width: 250px;
            padding: 10px;
            background-color: #f0f0f0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .drawer-container ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          .drawer-container ul li {
            margin-bottom: 16px;
          }
          .drawer-container ul li a,
          .drawer-container ul li button {
            text-decoration: none;
            color: #333;
            font-weight: 500;
            display: block;
            padding: 10px;
            border-radius: 4px;
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;
          }
          .drawer-container ul li a:hover,
          .drawer-container ul li button:hover {
            background-color: #007bff;
            color: #fff;
          }
          .drawer-container ul li button {
            border: none;
            background: none;
            width: 100%;
            text-align: left;
          }
        `}
      </style>

      <Container
        sx={{
          padding: '16px',
          maxWidth: { xs: '360px', md: '400px', lg: '700px' },
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        {/* Header Section with Toggle */}
        <Box className="header">
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#B22234', flexGrow: 1, textAlign: 'center' }}>
            Topic Search
          </Typography>
        </Box>

        {/* Drawer (Sidebar Menu) */}
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <Box
            className="drawer-container"
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ul>
              <li><Link to="/HomeScreen">Home</Link></li>
              <li><Link to="/UserProfileScreen">User Profile</Link></li>
              <li><Link to="/obl-team">OBL Team</Link></li>
              <li><Link to="/friends-search">Friends Search</Link></li>
              <li><Link to="/notifications">Notifications</Link></li>
              <li><Link to="/topic-search">Topic Search</Link></li>
              <li><Link to="/submit">Submit</Link></li>
              <li><button onClick={() => navigate('/login')}>Log Out</button></li>
            </ul>
          </Box>
        </Drawer>

        {/* Search Input and Filtered Topics */}
        <TextField
          label="Search topics..."
          variant="outlined"
          fullWidth
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />

        {/* Display Filtered Topics */}
        <Box sx={{ width: '100%' }}>
          {filteredTopics.map((item) => (
            <Card key={item} sx={{ padding: '16px', borderBottom: '1px solid #ccc', cursor: 'pointer', marginBottom: '8px' }}>
              <Typography sx={{ fontSize: '18px', color: '#B22234' }}>{item}</Typography>
            </Card>
          ))}
        </Box>
      </Container>
    </>
  );
};

export default TopicSearchScreen;
 
import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Drawer
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from 'react-router-dom';

const SubmitScreen = () => {
  const [articleName, setArticleName] = useState('');
  const [submissionDate, setSubmissionDate] = useState('');
  const [description, setDescription] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [videoURL, setVideoURL] = useState('');
  const [tags, setTags] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for sidebar toggle
  const navigate = useNavigate();

  const handleSubmit = () => {
    console.log({
      articleName,
      submissionDate,
      description,
      imageURL,
      videoURL,
      tags,
      email,
      phoneNumber,
    });
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <>
      {/* Add embedded CSS styles */}
      <style>
        {`
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding-bottom: 16px;
          }
          .drawer-container {
            width: 250px;
            padding: 10px;
            background-color: #f0f0f0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }
          .drawer-container ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          .drawer-container ul li {
            margin-bottom: 16px;
          }
          .drawer-container ul li a,
          .drawer-container ul li button {
            text-decoration: none;
            color: #333;
            font-weight: 500;
            display: block;
            padding: 10px;
            border-radius: 4px;
            transition: background-color 0.3s ease, color 0.3s ease;
            cursor: pointer;
          }
          .drawer-container ul li a:hover,
          .drawer-container ul li button:hover {
            background-color: #007bff;
            color: #fff;
          }
          .drawer-container ul li button {
            border: none;
            background: none;
            width: 100%;
            text-align: left;
          }
        `}
      </style>

      <Container
        sx={{
          padding: '16px',
          paddingTop: '50px', // Add padding to the top
          maxWidth: { xs: '360px', md: '400px', lg: '700px' },
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '110vh',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        {/* Header Section with Toggle */}
        <Box className="header">
          <IconButton onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#B22234', flexGrow: 1, textAlign: 'center' }}>
            Submit Your Journalistic Work
          </Typography>
        </Box>

        {/* Drawer (Sidebar Menu) */}
        <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
          <Box
            className="drawer-container"
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ul>
              <li><Link to="/HomeScreen">Home</Link></li>
              <li><Link to="/UserProfileScreen">User Profile</Link></li>
              <li><Link to="/obl-team">OBL Team</Link></li>
              <li><Link to="/friends-search">Friends Search</Link></li>
              <li><Link to="/notifications">Notifications</Link></li>
              <li><Link to="/topic-search">Topic Search</Link></li>
              <li><Link to="/submit">Submit</Link></li>
              <li><button onClick={() => navigate('/login')}>Log Out</button></li>
            </ul>
          </Box>
        </Drawer>

        {/* Submission Form */}
        <TextField
          label="Article or Video Name"
          variant="outlined"
          fullWidth
          value={articleName}
          onChange={(e) => setArticleName(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Date of Submission"
          variant="outlined"
          fullWidth
          value={submissionDate}
          onChange={(e) => setSubmissionDate(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Image URL"
          variant="outlined"
          fullWidth
          value={imageURL}
          onChange={(e) => setImageURL(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Video URL"
          variant="outlined"
          fullWidth
          value={videoURL}
          onChange={(e) => setVideoURL(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Tags"
          variant="outlined"
          fullWidth
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Email Address"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <TextField
          label="Phone Number"
          variant="outlined"
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          sx={{ marginBottom: '16px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ padding: '12px', fontSize: '16px', borderRadius: '8px' }}
        >
          Submit
        </Button>
      </Container>
    </>
  );
};

export default SubmitScreen;
